import { i18n } from '@/i18n/i18n';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { results } from '@/custom/menicon/constants';
import { JourneyTypeName, SurveyResult, GroupedSurveyResults } from '@/custom/menicon/models';

dayjs.extend(minMax);

export const getNumberValue = (results: { [question: string]: string[] }, question: string, value: string): number => {
  if (results[question]) {
    const index = results[question].findIndex((v) => v === value);
    if (index > -1) {
      return index + 1;
    }
  }
  return -1;
};

export const isSurveyOfType = (surveyResult: SurveyResult, type: JourneyTypeName) =>
  (type === JourneyTypeName.MENICON_BLOOM_DAY && surveyResult.type.EN.toLowerCase().includes('day')) ||
  ((type === JourneyTypeName.MENICON_BLOOM_NIGHT || type === JourneyTypeName.ACUVUE_ABILITI) &&
    surveyResult.type.EN.toLowerCase().includes('night'));

export const groupSurveyResultsByQuestion = (
  surveyResults: SurveyResult[]
): GroupedSurveyResults =>
  surveyResults.reduce((acc, surveyResult) => {
    const locale: string =
      Object.keys(surveyResult.type).find(
        (l) => l.toUpperCase() === i18n.global.locale.value.split('-')[0].toUpperCase()
      ) || 'EN';
    surveyResult.results.map((result) => {
      const value = {
        date: surveyResult.date,
        answer: getNumberValue(results, result.question.EN, result.answer.EN),
        stringValue: result.answer[locale]
      };
      const question = result.question[locale];
      if (!acc[question]) {
        acc[question] = [];
      }
      acc[question].push(value);
    });
    return acc;
  }, {} as GroupedSurveyResults);

export const getFirstAndLastSurveyDates = (surveyResults: SurveyResult[]): { first: string; last: string } => {
  const dates = surveyResults.map((result) => dayjs(result.date));
  if (dates.length > 1) {
    return {
      first: dayjs.min(...dates).format('D MMM YYYY'),
      last: dayjs.max(...dates).format('D MMM YYYY')
    };
  } else if (dates.length === 1) {
    const date = dayjs(dates[0]).format('D MMM YYYY');
    return {
      first: date,
      last: date
    };
  }
  return {
    first: '',
    last: ''
  };
};
