import { Encounter, Laterality } from '@/custom/menicon/models';

export const getOrderNewLens = (
  value: Partial<Encounter>,
  lateralities: Laterality[] = [Laterality.right, Laterality.left]
): boolean => {
  if (!value) {
    return true;
  }

  const rightEyeConditions =
    (value.r_is_expected_treatment_zone === true && value.r_is_expected_centration === true) ||
    (value.r_is_expected_treatment_zone === false &&
      value.r_is_expected_centration === true &&
      value.r_pattern === 'a');

  const leftEyeConditions =
    (value.l_is_expected_treatment_zone === true && value.l_is_expected_centration === true) ||
    (value.l_is_expected_treatment_zone === false &&
      value.l_is_expected_centration === true &&
      value.l_pattern === 'a');

  return !lateralities.reduce(
    (acc, laterality) => acc && (laterality === Laterality.right ? rightEyeConditions : leftEyeConditions),
    true
  );
};
